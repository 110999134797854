<template>
    <!-- A container for the (replaced) pdf container, to allow proper scrolling -->
    <div ref="pdfScrollContainer">
        <a
            v-if="usePdfLinkFallback"
            :href="mediaUrl"
            alt="Show this track's PDF in an external viewer"
            target="_blank"
            >{{ mediaUrl }}</a
        >
        <div
            v-else
            ref="pdfContainer"
            :style="{
                'min-height': isFullscreen ? '100vh' : availableHeight + 'px',
                'max-height': isFullscreen ? '100vh' : availableHeight + 'px',
                width: '100%',
            }"
        ></div>
    </div>
</template>

<script setup lang="ts">
const pdfContainer = ref(null);
const pdfScrollContainer = ref(null);

/** A track variant that displays a PDF document, either as link or as an expandable inline viewer */
import { computed, ref, inject, onMounted, onUpdated, watchEffect } from 'vue';

import PDFObject from 'pdfobject';
import VueScrollTo from 'vue-scrollto';

const props = defineProps({
    /** The PDF URL
     */
    mediaUrl: {
        type: String,
        required: true,
    },

    /** Whether to show the PDF are in full screen (full height)
     */
    isFullscreen: {
        type: Boolean,
        required: true,
    },
});

import { navbarCompensationHeightInjectionKey } from '@/AppInjectionKeys';
import FileHandler from '@/store/filehandler';
const navbarCompensationHeight = inject(navbarCompensationHeightInjectionKey);

/** Gets the net available available window height
 * @remarks This is used for convenience to the user, to minimize
 * required vertical scrolling, while observing the application's navbar(s)
 */
const availableHeight = computed(() => {
    const fullvh = Math.round(window.innerHeight);
    if (props.isFullscreen) {
        return fullvh;
    }
    const availableHeight = fullvh - (navbarCompensationHeight?.value ?? 0);
    return availableHeight;
});

const initPDF = () => {
    if (pdfContainer.value) {
        console.debug(
            'PdfElement::Rendering PDF for mediaUrl: ',
            props.mediaUrl,
        );
        try {
            const success = PDFObject.embed(
                props.mediaUrl,
                pdfContainer.value,
                {
                    pdfOpenParams: { view: 'FitH' },
                    height: props.isFullscreen
                        ? '100vh'
                        : availableHeight.value + 'px',
                    width: '100%',
                    forcePDFJS: false,
                    PDFJS_URL: '/pdfjs/web/viewer.html?v=2',
                },
            );
            if (!success) {
                console.warn('PdfElement::Rendering failed');
                usePdfLinkFallback.value = true;
            }
        } catch (error) {
            console.warn('PdfElement::Rendering failed because: ', error);
            usePdfLinkFallback.value = true;
        }

        /** When using non-full-screen, scroll back to the pdf viewport */
        if (!props.isFullscreen) {
            scrollToPdf();
        }
    }
};

onMounted(initPDF);
onUpdated(initPDF);

/** Visually scrolls to the PDF, making it visually at the top of
 * the view.
 */
function scrollToPdf() {
    console.debug('PdfElement::scrollToPdf');
    VueScrollTo.scrollTo(pdfScrollContainer.value, {
        /** Always scroll, make it on top of the view */
        force: true,
        /** empirical value (taking into account the non-existing fixed top navbar) */
        offset: 0,
        /** Avoid interference with the key press overlay */
        cancelable: false,
    });
}

const usePdfLinkFallback = ref(false);

watchEffect(() => {
    usePdfLinkFallback.value = FileHandler.isValidHttpUrl(props.mediaUrl);
});
</script>

<style>
/** This class is generated by PDFObject */
.pdfobject-container {
    position: absolute;
    left: 0;
    right: 0;
    /** keep the PDF inside the track outline */
    display: contents;
    overflow: hidden;
}
</style>
